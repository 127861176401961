
import { defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";

const VEstablishmentProcedureConfirmDeletion = defineComponent({
  name: "VEstablishmentProcedureConfirmDeletion",
  components: { CModal },
  setup() {
    useHead({ title: "Confirmar exclusão do procedimento | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ confirm: false });

    function handleClose() {
      router.back();
    }

    async function deleteEstablishmentProcedure() {
      loading.confirm = true;
      const response = await store.dispatch("deleteEstablishmentProcedure", { _id });
      loading.confirm = false;

      if (response?.status === 200) {
        store.dispatch("getEstablishmentProcedures");
        handleClose();
      }
    }

    return { loading, deleteEstablishmentProcedure, handleClose };
  },
});

export default VEstablishmentProcedureConfirmDeletion;
